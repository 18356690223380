
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pb-color{
  background-color:#63087d;
}
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root,
  .button-filled {
    background-color: #63087d !important;
  }

  .css-1rwt2y5-MuiButtonBase-root-MuiButton-root,
  .button-outlined {
    border: 1px solid #63087d9e !important;
    color: #63087d !important;
  }

  
